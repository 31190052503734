import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { SelectOption } from '@/interfaces/common.interface'

@Component({
  name: 'GtrRegistrationModuleNewPromoCodeView',
  computed: {
    ...mapState('option', ['option_groups'])
  }
})
export default class GtrRegistrationModuleNewPromoCodeView extends GtrSuper {
  option_groups!: any[]

  data () {
    return {
      submitting: false,
      showGeneratePromoCodeFields: false,
      promoCode: {
        code: null,
        group_discount_type: 'ALL_GROUP_MEMBERS',
        discount_type: null,
        discount_amount: null,
        discount_percent: null,
        discount_fixed_price: null,
        cap: null,
        active_start_date: null,
        active_end_date: null,
        notes: null,
        reg_types: [],
        promo_code_options: []
      },
      promoCodeGenerateItems: [
        'Single',
        'Multiple'
      ],
      promoCodeGenerateSingleOrMultiple: null,
      promoCodeMultipleQuantity: 2,
      true_false_options: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      group_style_options: [
        {
          text: 'All Group Members',
          value: 'ALL_GROUP_MEMBERS'
        },
        {
          text: 'Single Group Member',
          value: 'SINGLE_GROUP_MEMBER'
        }
      ],
      discount_type_items: [
        {
          text: 'Amount',
          value: 'AMOUNT'
        },
        {
          text: 'Percent',
          value: 'PERCENT'
        // },
        // {
        //   text: 'Fixed Price',
        //   value: 'FIXED'
        }
      ]
    }
  }

  async mounted () {
    try {
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('promoCodeGenerateSingleOrMultiple')
  handleSingleOrMultiplePromoCode (val) {
    if (val === 'Multiple') {
      this.$data.promoCode.code = ''
    }
  }

  private handleOptionSpecificChange (value: boolean) {
    if (!value) {
      this.$data.promoCode.promo_code_options = []
    }
  }

  private handleRegtypeSpecificChange (value: boolean) {
    if (!value) {
      this.$data.promoCode.reg_types = []
    }
  }

  async generateSinglePromoCode () {
    const code = await this.$store.dispatch('promocodes/generateSinglePromoCode', this.$route.params.event_uuid)
    this.$data.promoCode.code = code
  }

  private optionGroupItemTitle (titleText: string, groupOptions: SelectOption[]): string {
    const selectedOptions = this.$data.promoCode?.promo_code_options?.filter(selectedOptionUuid => groupOptions.some(groupOption => groupOption.value === selectedOptionUuid)).length ?? 0
    const optionsInGroup = groupOptions.length
    return `<span style="color: var(${selectedOptions === optionsInGroup ? '--complete-dark' : '--grey-75'});">${titleText} (${selectedOptions}/${optionsInGroup})</span>`
  }

  get options_with_prices (): { [index: string]: SelectOption[] } {
    if (!this.option_groups?.length) {
      return {}
    }

    const pricedOptions: { [index: string]: SelectOption[] } = {}
    for (let i = 0; i < this.option_groups.length; i++) {
      const optionGroup = this.option_groups[i]
      if (optionGroup.type === 'LROPTIONGRP') {
        continue
      }
      for (let j = 0; j < optionGroup.options.length; j++) {
        const option = optionGroup.options[j]
        if (option.pricing?.length) {
          if (!pricedOptions[optionGroup.name]) {
            pricedOptions[optionGroup.name] = [] as SelectOption[]
          }
          pricedOptions[optionGroup.name].push({
            text: option.name,
            value: option.uuid
          })
        }
      }
    }
    return pricedOptions
  }

  get registration_types (): SelectOption[] {
    if (!this.option_groups?.length) {
      return []
    }
    const registration_types_array = this.option_groups
      .filter(group => group.name === 'Registration Types')
      .shift().options
    const registration_types: SelectOption[] = []
    for (let i = 0; i < registration_types_array.length; i++) {
      registration_types.push({
        text: registration_types_array[i].name,
        value: registration_types_array[i].uuid
      })
    }
    return registration_types
  }

  async submit () {
    const form = (this.$refs.newPromoCodeForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          data: {
            code: this.$data.promoCode.code,
            discount_percent: this.$data.promoCode.discount_percent,
            discount_amount: this.$data.promoCode.discount_amount,
            discount_type: this.$data.promoCode.discount_type,
            group_discount_type: this.$data.promoCode.group_discount_type,
            discount_fixed_price: this.$data.promoCode.discount_fixed_price,
            cap: this.$data.promoCode.cap,
            active_start_date: this.$data.promoCode.active_start_date,
            active_end_date: this.$data.promoCode.active_end_date,
            regtype_specific: this.$data.promoCode.regtype_specific,
            reg_types: this.$data.promoCode.reg_types,
            option_specific: this.$data.promoCode.option_specific,
            promo_code_options: this.$data.promoCode.promo_code_options,
            notes: this.$data.promoCode.notes
          }
        }
        if (this.$data.promoCodeGenerateSingleOrMultiple === 'Multiple') {
          delete payload.data.code
          payload.data.qty = this.$data.promoCodeMultipleQuantity
        }
        await this.$store.dispatch('promocodes/createPromoCode', payload)
        Container.get(Notification).success('Promo code successfully created.')
        this.$router.push({
          name: 'level-two.modules.registration.promo-codes'
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }
}
